import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

const { getIdentityTypesByCountry } = backOffice.identityTypes;

export const IDENTITY_TYPES_SCOPES = {
  byCountry: 'byCountry',
};

const identityTypes = createCustomStore(({ runAsyncFlow }) => withScopes(IDENTITY_TYPES_SCOPES, ({
  actions: {
    async getIdentityTypesByCountry({ commit }, countryUuid) {
      await runAsyncFlow(commit, {
        request: getIdentityTypesByCountry,
        params: [countryUuid],
        scope: IDENTITY_TYPES_SCOPES.byCountry,
      });
    },
  },
})));

export default identityTypes;
